
.td-img-box {
  display: flex;
  align-items: center;
  padding-left: 24px;
  .td-img {
    display: inline-block;
    vertical-align: middle;
    width: 48px;
    height: 48px;
    margin: 4px 0;
    position: relative;
    border: 1px solid #f5f5f5;
  }
  .td-tit {
    line-height: 1.2;
    padding-left: 14px;
  }
}
.distributor-td {
  .ope {
    width: auto;
    display: inline-block;
    cursor: pointer;
    margin-right: 14px;
    &:hover {
      color: var(--color);
    }
  }
}
.distributor-page-box {
  margin-top: 14px;
  background: #fff;

  .distributor-page-body {
    padding: 16px;

    .title-line-box {
      margin-bottom: 16px;
    }

    .form-item-box {
      width: 400px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
    }
    .ant-form-item {
      .ant-form-item-control-input {
        width: 400px;
      }
    }
    .tips {
      margin-left: 10px;
      color: var(--font-tips);
    }
    .wrap-box {
      position: relative;
      .explain {
        margin-top: -20px;
        position: absolute;
        left: 552px;
        top: 26px;
        .explain-hover {
          right: -74px;
        }
      }
      .visible-icon {
        left: 512px;
        .icon-visible-btn {
          font-size: 12px;
          &.active {
            color: var(--color);
          }
        }
      }
      .reset-btn {
        left: 552px;
        color: #20ade5;
      }
    }
    .txt-hint {
      margin-right: 4px;
    }
  }
}
.btn-add {
  display: inline-block;
  vertical-align: middle;
  width: 72px;
  height: 32px;
  line-height: 32px;
  padding: 0 18px;
  background-color: var(--color);
  color: #fff;
  white-space: nowrap;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
}
.transfer-order-btn {
  position: absolute !important;
  right: 30px;
  top: 10px;
}
