.goods-list-wrap {
  width: 1360px;
  min-width: 1360px;
  margin: 14px auto;
  background: #fff;

  .ant-pagination {
    padding: 32px 0 8px;
    text-align: center;
  }

  .ant-spin {
    display: block;
    padding: 48px 0 36px;
  }

  .ant-empty {
    padding: 48px 0 36px;
  }
}

.goods-list-box {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: #f5f5f5;
}

.goods-list-card {
  background-color: #fff;
  margin-bottom: 20px;
  width: 325px;
  //border: 1px solid var(--border-deep);
  cursor: pointer;
  .img-box {
    height: 195px;
  }
  .retail {
    font-size: 16px;
    color: var(--font-tips);
    //text-decoration: line-through;
  }
  &:hover {
    //border-color: var(--color);
    border: 1px solid var(--color);
  }

  &:not(:nth-child(4n)) {
    margin-right: 20px;
  }

  .img-box {
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .goods-info-box {
    padding: 12px;
    display: flex;
    flex-direction: column;

    .goods-info-row {
      flex: 1;
      font-size: 14px;
      color: var(--font-title);
      line-height: 1.4;

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }

    .title-row {
      //min-height: 38px;
    }

    .tag-row {
      white-space: nowrap;
      overflow: hidden;
      min-height: 20px;

      .tag {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        margin-right: 8px;
        border-radius: 4px;
        background: var(--orange-red);
        padding: 2px 8px;

        &:nth-child(n+4) {
          display: none;
        }
      }
    }

    .price-row {
      font-size: 18px;
      color: var(--font-high);

      i {
        font-size: 14px;
      }

      span {
        font-size: 14px;
        color: var(--font-tips);
        text-decoration: line-through;
        display: inline-block;
        padding-left: 8px;
      }

    }

    .price-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 18px;
      color: var(--font-high);

      i {
        font-size: 14px;
      }

      span {
        font-size: 14px;
        color: var(--font-tips);
        text-decoration: line-through;
        display: inline-block;
        padding-left: 0;
      }
    }

    .to-cart {
      text-align: right;

      span {
        display: inline-block;
        width: 28px;
        height: 28px;
        //padding: 0 8px;
        background: var(--color);
        color: #fff;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          opacity: .8;
        }

        .iconfont {
          font-size: 12px;
        }
      }
    }
  }
}

.goods-card-cover {
  text-align: center;

  .goods-list-card {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

.goods-list-row {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid var(--border-deep);

  .check-box {
    width: 20px;
    margin-right: 10px;
    align-self: center;
    cursor: pointer;

    &:hover {

      color: var(--color);
    }

    .icon-check-s {
      color: var(--color);
    }
  }

  .image-box {
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    text-align: center;
    line-height: 120px;

    img {
      max-height: 100%;
      max-width: 100%;
    }

    .tips-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 32px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, .8);
    }
  }

  .detail-box {
    width: 348px;
    padding-left: 24px;
    word-break: break-all;
    cursor: pointer;

    .name {
      font-size: 14px;
    }

    .tips {
      font-size: 12px;
      color: var(--font-tips);
    }
  }

  .describe-box {
    width: 336px;
    padding-left: 24px;

    .price {
      font-size: 16px;
      color: var(--font-high);
    }

    .unit {
      font-size: 12px;
      color: var(--font-tips);
    }
  }

  .cart-box {
    width: 246px;
    padding-left: 24px;

    .stock {
      font-size: 14px;
      color: var(--font-tips);
    }

    .label {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: var(--font-title);
      border-radius: 12px;
      background: #F5F5F5;
    }
  }

  .operate-box {
    align-self: center;

    .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: var(--color);
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      &:disabled {
        background: var(--disabled);
      }
    }
  }
}

.goods-detail-head {
  width: 1360px;
  display: flex;
  padding: 20px 16px;

  .goods-detail-box {
    flex: 1;
    padding-left: 32px;
    width: 0;

    .name {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 600;
      color: var(--font-title);
      word-break: break-all;
      white-space: normal;
    }

    .code {
      color: var(--font-tips);

      .item {
        display: inline-block;
        vertical-align: top;
        word-break: break-word;
        margin-right: 24px;
      }

      .icon-shuoming {
        margin-right: 2px;
      }
    }
  }

  .coupon-box {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    border-bottom: 2px dashed #e3e3e3;
    background: rgba(245, 245, 245, 1);

    .label {
      padding-right: 8px;
    }

    .more-btn {
      cursor: pointer;
    }

    .icon-jump {
      font-size: 12px;
    }

    .coupon-list-box {
      flex: 1;
      overflow: hidden;
      height: 20px;
      padding: 1px 0;
    }

    .coupon-label {
      align-items: center;
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-hint);
      position: relative;
      margin: 0 4px 0 2px;
      padding: 0 8px;
      border-radius: 2px;
      background: radial-gradient(circle at left, transparent 4px, #FFEFF0 0) left / 60% no-repeat,
      radial-gradient(circle at right, transparent 4px, #FFEFF0 0) right / 60% no-repeat;
      filter: drop-shadow(0 0 1px var(--color-hint));
    }
  }

  .price-wrap-row {
    padding: 12px;
    background-color: #f5f5f5;
    background-image: url("../../assets/images/goods_bg_details_price.png");
    background-repeat: repeat-x;
    background-position: bottom left;
    margin: 16px 0;

    .price-row {
      display: flex;
      align-content: center;
      align-items: center;
      margin: 8px 0;

      .title {
        width: 76px;
        color: var(--font-normal);
        font-size: 14px;
      }

      .price {
        flex: 1;
        font-size: 14px;
        color: var(--font-title);

        .hint {
          font-size: 24px;
          font-weight: 600;
          color: var(--font-high);
        }

        .line-through {

          text-decoration: line-through;
        }
      }
      .retail-price {
        color: var(--font-tips);
        font-size: 18px;
      }
    }

    .promotion-row {
      .title {
        align-self: flex-start;
      }

      .price {
        width: 0;
        display: flex;

        .more {
          cursor: pointer;
          white-space: nowrap;
          color: var(--font-tips);

          &:hover {
            color: var(--color)
          }
        }
      }

      .high {
        color: var(--font-high);
      }
    }

    .promotion-item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      margin-bottom: 4px;

      .img-box {
        display: inline-block;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        border: 1px solid var(--border-deep);
        overflow: hidden;
        margin: 0 4px;
      }
    }
  }

  .product-wrap {
    display: flex;
    padding: 0 12px;
    margin: 16px 0;

    .title {
      width: 100px;
      font-size: 14px;
      text-align: left;
      color: var(--font-normal);
      height: 40px;
      line-height: 40px;
    }

    .product-main {
      flex: 1;

      .product-item {
        display: inline-block;
        position: relative;
        padding: 6px 12px 8px;
        min-width: 74px;
        line-height: 1.5;
        margin-bottom: 8px;
        margin-right: 8px;
        text-align: center;
        border: 1px solid #b3b3b3;
        cursor: pointer;
        color: var(--font-title);
        background: #fff;
        max-width: 300px;

        &:not(:disabled):hover,
        &:not(:disabled).active {
          border-color: var(--color);
          color: var(--color);
        }

        &:disabled {
          color: #fff;
          border: 1px solid var(--disabled);
          background: var(--disabled);
          cursor: not-allowed;
        }
      }
    }
  }

  .product-info-wrap {
    position: relative;
    font-size: 12px;
    color: var(--font-title);
    margin-top: 42px;

    .product-info-row {
      display: flex;
      align-content: center;
      align-items: center;
      height: 50px;
      padding: 0 16px;
      background: #f5f5f5;
      border: 1px solid #e3e3e3;

      .format-wrap {
        width: 28%;
        padding-right: 8px;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 50px;
          background: var(--border-deep);
          position: absolute;
          top: 0;
          right: 0;
          transform: translateY(-27%);
        }
      }

      .price-wrap {
        width: 20%;
        padding: 0 8px;
        color: var(--font-high);
      }

      .unit-wrap {
        flex: 1;
      }

      .stock-wrap {
        padding: 16px;
        max-width: 15%;
      }

      .quantity {
        margin-right: 10px;
      }
    }

    .product-num-row {
      display: none;
      position: absolute;
      left: 0;
      bottom: 50px;
      background: #fafafa;
      width: 100%;
      height: 30px;
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: var(--border-deep);
      align-content: center;
      align-items: center;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;

      .number-total-wrap {
        width: 20%;
        border-right: 1px solid var(--border-deep);
        padding-right: 12px;
        text-align: right;
      }

      .price-total-wrap {
        flex: 1;
        padding-left: 32px;
      }

      .detail-btn {
        cursor: pointer;

        .iconfont {
          font-size: 12px;
          padding-left: 4px;
        }
      }
    }

    .cart-list-row {
      display: none;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: var(--border-deep);
      position: absolute;
      width: 100%;
      background: #fff;
      bottom: 79px;
      left: 0;
      z-index: 4;

      .product-info-row {
        border: 0 none;
        border-bottom: 1px solid var(--border-deep);
        background: #fff;
      }
    }
  }

  .btn-wrap {
    margin: 24px 0;

    .buy-btn {
      width: 150px;
      height: 40px;
      border: 1px solid var(--color);
      font-size: 16px;
      font-weight: 600;
      color: var(--color);
      margin-right: 24px;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }
    .buy-btn-new {
      width: 150px;
      height: 40px;
      border: 1px solid #f78c61;
      background-color: #f78c61 !important;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      font-size: 16px;
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    .add-btn-new {
      width: 150px;
      height: 40px;
      background-color: #cb0d1c;
      border: 0 none;
      font-size: 16px;
      color: #ffffff;
      cursor: pointer;
      margin-right: 32px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      &:hover {
        opacity: .8;
      }
    }

    .disabled-btn {
      width: 150px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      border: 1px solid var(--disabled);
      background: var(--disabled);
      margin-right: 24px;
      cursor: pointer;
    }

    .add-btn {
      width: 150px;
      height: 40px;
      background-color: var(--color);
      border: 0 none;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
      margin-right: 32px;

      &:hover {
        opacity: .8;
      }
    }

    .collect-btn {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      .iconfont {
        margin-right: 6px;
      }

      &:hover, &.active {
        color: var(--color);
      }
    }
  }
}

.goods-package-detail-head {
  .price-wrap-row {
    .price-row {
      .item {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .item:nth-child(2) {
        .title {
          width: 80px;
        }
      }
      .item:nth-child(3) {
        .forecast {
          font-size: 12px;
          color: var(--font-title);
          white-space: nowrap;
          .fb {
            font-weight: 700;
            font-size: 15px;
          }
          .day {
            display: inline-block;
            padding: 0 6px 0 16px;
            color: var(--color-hint);
            font-weight: 700;
            font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
          }

          .time {
            display: inline-block;
            font-size: 10px;
            line-height: 28px;
            min-width: 28px;
            margin: 0 5px;
            text-align: center;
            background: var(--color-hint);
            border-radius: 3px;
            color: #fff;
            font-weight: 700;
            font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
          }
          &.disabled {
            color: var(--font-tips);

            .day {
              color: var(--font-tips);
            }

            .time {
              background: #D2D2D2;
            }
          }

          &.invalid {
            color: var(--font-title);

            .day {
              color: var(--font-title);
            }

            .time {
              background: #333;
            }
          }
        }
      }
    }
  }
}

.package-list-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  height: 200px;
  overflow: auto;
  .goods-item-cart {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    height: 118px;
    .img-box {
      width: 90px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      overflow: hidden;
    }

    .info-box {
      padding-left: 8px;
      line-height: 1;
      font-size: 14px;
      flex: 1;

      p {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .tit {
        font-weight: 600;
        color: var(--font-title);
      }

      .format {
        color: var(--font-normal);
      }

      .price {
        color: var(--font-tips);

        i {
          font-size: 16px;
          color: #FA3815;
          font-weight: 600;
        }
      }
    }
  }
}


.video-pop-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;

  &:hover {
    .video-close, .video-play-box, .video-pause-box {
      display: block;
    }
  }

  .video-close {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    text-align: right;
    padding: 0 16px;
    background: rgba(0, 0, 0, .4);
    z-index: 2;

    i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .time-display-box {
    position: absolute;
    left: 24px;
    bottom: 24px;
    font-size: 14px;
    color: #fff;
    z-index: 3;
    padding: 4px 8px;
    border-radius: 100px;
    background: rgba(0, 0, 0, .4);

  }

  .video-play-box, .video-pause-box {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

.play-video-btn {
  position: absolute;
  left: 16px;
  bottom: 40px;
  width: 90px;
  height: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
}

.goods-product-box {
  margin-top: 32px;
}

.goods-product-item {
  display: flex;
  margin-bottom: 32px;
  font-size: 14px;
  color: var(--font-tips);

  .detail-box {
    width: 340px;
    padding-right: 16px;

    .format {
      line-height: 24px;
      color: var(--font-normal);

      .code {
        color: var(--font-tips);
      }
    }

    .icon-spec-picture {
      color: var(--font-tips);
      margin-right: 4px;
      font-size: 14px;

      &:hover {
        opacity: .8;
        cursor: pointer;
      }
    }

    .iconimg {
      color: var(--font-title);
    }
  }

  .describe-box {
    margin-top: 8px;

    .row {
      padding-right: 24px;
    }

    .iconfont {
      color: var(--font-high);
    }
  }

  .price-box {
    width: 120px;

    .price {
      font-size: 16px;
      color: var(--font-high);

      .unit {
        font-size: 12px;
        color: var(--font-tips);
      }
    }
  }

  .uint-box {
    margin-top: 8px;
  }

  .cart-box {
    width: 126px;

    .stock {
      margin-top: 8px;
    }
  }

  .operate-box {
    align-self: flex-end;

    .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: var(--color);
      color: #fff;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      &:disabled {
        background: var(--disabled);
      }
    }
  }
}

.goods-detail-introduce {
  margin: 24px auto 0;
  word-break: break-all;
  white-space: normal;

  img {
    min-width: 100%;
  }
}

.goods-detail-property {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;

  .item {
    display: inline-block;
    width: 294px;
    padding-right: 120px;
    vertical-align: top;
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--font-normal);
  }
}

.goods-detail-tabs {
  .ant-tabs-nav-container {
    height: 54px;
    padding-top: 8px;
    padding-left: 16px;
    background: #F5F5F5;

    .ant-tabs-nav {
      .ant-tabs-tab {
        &:hover,
        &:active {
          color: var(--color);
        }
      }

      .ant-tabs-tab-active {
        color: var(--color);
      }
    }

    .ant-tabs-ink-bar {
      background-color: var(--color);
    }
  }

  .ant-pagination {
    margin: 62px auto 24px;
    text-align: center;
  }

  .ant-empty {
    margin: 48px 0 24px;
  }
}

.recommend-list-box {
  margin-bottom: 40px;

  .ant-carousel {
    .slick-dots-bottom {
      bottom: -18px;
      margin-bottom: 0;
    }

    .slick-dots {
      li {
        height: 8px;
        width: auto;

        button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #666;
        }

        &.slick-active {
          width: auto;

          button {
            background: red;
          }
        }
      }
    }
  }
}

.goods-brand-page-main {
  background: #fff;
  min-height: 600px;
  padding: 16px;

  .first-letter-wrap {
    .item {
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      border: 1px solid transparent;
      cursor: pointer;
      font-size: 14px;
      color: var(--font-title);

      &:hover {
        color: var(--color);
      }

      &.active {
        border-color: var(--color);
        color: var(--color);
      }
    }
  }

  .brand-list-wrap {
    position: relative;
    display: block;
    transition: all 0.4s ease-out;
    overflow: hidden;
    max-width: 100%;

    .brand-card {
      position: absolute;
      display: inline-block;
      vertical-align: top;
      width: 222px;
      height: 116px;
      border: 1px solid var(--border-deep);
      transition: all 0.4s ease-out;
      overflow: hidden;

      &:hover {
        .brand-hover {
          opacity: 1;
          transition: opacity .3s linear;
        }
      }
    }

    .brand-main {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      span {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .brand-hover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      opacity: 0;
      transition: opacity .3s linear;
      cursor: pointer;

      .box {
        text-align: center;
      }

      .tit {
        font-size: 16px;
        line-height: 22px;
      }

      .tips {
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}


.goods-sn-search {
  padding: 0 48px;
  text-align: center;
  min-height: 540px;

  .hint {
    width: 292px;
    text-align: center;
    margin: 0 auto;
    padding: 110px 0 26px;
    font-size: 20px;
    color: var(--font-tips);
  }

  .search-status-wrap {
    text-align: center;
    padding: 30px 0 38px;
    margin: 0 auto 42px;
    width: 1088px;
    border-bottom: 1px solid var(--border-deep);

    .svg-icon {
      width: 45px;
      height: 45px;
      margin-bottom: 16px;
    }

    .tips {
      font-size: 14px;
      color: var(--font-title);
    }
  }
}

.goods-sn-search-value {
  .value {
    width: 638px;
    height: 58px;
    padding: 0 16px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 53px;
    background: #FFFFFF;
    border-radius: 37px;
    border: 1px solid #DCDCDD;
  }
}


.goods-sn-search-btn {
  text-align: center;
  margin-bottom: 24px;

  .btn {
    width: 234px;
    height: 58px;
    line-height: 56px;
    font-size: 20px;
    border: 0 none;
    border-radius: 37px;
    background-color: var(--color);
    color: #fff;
  }
}


.goods-sn-search-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 50px;

  .img-box {
    width: 212px;
    height: 212px;
    line-height: 212px;
    margin-right: 12px;
    overflow: hidden;
    text-align: center;
  }

  .detail {
    flex: 1;
    padding: 0 42px;
    font-size: 14px;
    text-align: left;
    color: var(--font-tips);
    line-height: 2;
  }
}

.goods-package-list {
  margin-top: 16px;
  background: #fff;
  .goods-package-list-item {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #F5F5F5;
    padding-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 8px;
  }

  .headline {
    padding: 0 24px;
    line-height: 45px;
    font-size: 15px;
    color: var(--font-title);
    display: flex;
    justify-content: space-between;

    .name {
      font-size: 15px;
      font-weight: 700;
      white-space: nowrap;
    }

    .forecast {
      font-size: 12px;
      color: var(--font-title);
      white-space: nowrap;
      .fb {
        font-weight: 700;
        font-size: 15px;
      }
      &.disabled {
        color: var(--font-tips);

        .day {
          color: var(--font-tips);
        }

        .time {
          background: #D2D2D2;
        }
      }

      &.invalid {
        color: var(--font-title);

        .day {
          color: var(--font-title);
        }

        .time {
          background: #333;
        }
      }
    }

    .day {
      display: inline-block;
      padding: 0 6px 0 16px;
      color: var(--color-hint);
      font-weight: 700;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
    }

    .time {
      display: inline-block;
      font-size: 10px;
      line-height: 28px;
      min-width: 28px;
      margin: 0 5px;
      text-align: center;
      background: var(--color-hint);
      border-radius: 3px;
      color: #fff;
      font-weight: 700;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif;
    }
  }

  .goods-package-cart-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    .img-box {
      display: inline-block;
      vertical-align: top;
      width: 95px;
      height: 95px;
      margin-right: 10px;
      text-align: center;
      line-height: 95px;
      overflow: hidden;
      border: 1px solid var(--border-deep);
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .detail {
      font-weight: 700;
    }
    .cart-info {
      line-height: 1;
      padding: 20px 0 28px;

      .tips {
        font-size: 12px;
        color: var(--font-tips);
        margin-bottom: 10px;
      }

      .price-box {
        display: flex;
        justify-content: space-between;

        .label {
          line-height: 48px;
          font-size: 14px;
          .price {
            color: var(--color-hint);
          }
        }
      }
    }
  }
}

.cheaper-price {
  display: inline-block;
  padding-right: 10px;
  height: 24px;
  line-height: 20px;
  border: 2px solid #FA3815;
  border-radius: 2px;
  overflow: hidden;
  color: #FA3815;
  font-size: 14px;
  font-weight: 600;
  span {
    position: relative;
    display: inline-block;
    width: 28px;
    vertical-align: top;
    text-align: center;
    background: #FA3815;
    color: #fff;
    height: 140%;
    line-height: 20px;
    overflow: hidden;
  }
}

.package-price-box {
  display: flex;
  padding: 0 24px 12px 24px;
  font-size: 15px;
  font-weight: 700;
  .flex-item {
    display: flex;
  }
  .flex-item:nth-child(1) {
    margin-right: 36px;
    .label {
      color: var(--color-hint);
    }
  }
}

.goods-package-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 10px auto;

  .ant-pagination {
    padding: 32px 0 8px;
    text-align: center;
  }

  .ant-spin {
    display: block;
    padding: 48px 0 36px;
  }

  .ant-empty {
    padding: 48px 0 36px;
  }
}

.goods-batch-order-box {
  background-color: #fff;
  position: relative;
  .goods-batch-order-wrap {
    width: 1200px;
    min-width: 1200px;
    height: 700px;
    margin: 10px auto;
    padding: 0 24px 24px 24px;
    .cont-table {
      height: 600px;
      overflow-y: auto;
    }
  }
  .operate-btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 12px 0 24px;
    background: rgb(248, 248, 248);
    .info {
      font-size: 12px;
      color: #999;
    }
    .btn-group {
      height: 100%;
      .btn {
        width: 100px;
        height: 100%;
        border: none;
        color: #fff;
        background-color: var(--color);
      }
      .prev-btn {
        background-color: #fff;
        color: var(--color);
        margin-right: 12px;
        border: 1px solid var(--color);
      }
    }
  }
  .step-box {
    padding: 12px 24px 12px 12px;
    color: var(--font-tips);
    .mark {
      color: var(--font-title);
    }
  }
  .import-info-main-box {
    display: flex;
    justify-content: space-between;
  }
  .import-info-tit {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
  }

  .import-info {
    margin-bottom: 20px;
    padding: 24px;
    background: rgba(248, 248, 248, .5);
    .txt {
      font-size: 12px;
      margin-bottom: 12px;
      padding-left: 20px;
      color: #999;
      line-height: 1.5;
      p {
        margin-bottom: 6px;
      }
    }
  }

  .import-info-main {
    margin: 0 0 10px 20px;
    .select-width {
      width: 320px;
    }
    .tit {
      width: 60px;
    }
    .download-txt {
      padding: 6px 10px;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: var(--font-tips);
      line-height: 1em;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid var(--border-deep);
      border-radius: 3px;
      i {
        font-size: 14px;
      }
    }
  }

  .import-box-main {
    width: 500px;
    font-size: 0;
    .txt {
      display: inline-block;
      vertical-align: middle;
      width: 380px;
      height: 36px;
      font-size: 14px;
      padding-left: 1em;
      background: #fff;
      border: 1px solid #E3E3E3;
      border-radius: 4px 0 0 4px;
      margin: 0;
      line-height: 1em;
      color: #333;
    }
    .import-btn {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 120px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      border-radius: 0 4px 4px 0;
      font-size: 14px;
      background: var(--color);
      color: #fff;
      cursor: pointer;
      input[type="file"] {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
  }

}

