.curtain-size-card {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  .title {
    width: 90px;
    text-align: right;
    margin-right: 6px;
    color: var(--font-tips);
  }
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    .row-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      color: var(--font-tips);
      .txt {
        width: 40px;
      }
      .wrap {
        flex: 1;
        position: relative;
        i {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 12px;
        }
      }
      input {
        border: 1px solid #CACDD1;
        width: 230px;
        height: 32px;
        text-align: right;
        padding-right: 36px;
        margin-top: -2px;
        color: var(--font-tips);
      }
    }
  }
}
.inner-pleat-number-card {
  margin-top: 8px;
  .main {
    .row-box {
      .txt {
        width: 90px;
        text-align: right;
      }
      input {
        text-align: left;
      }
    }
  }
}
.drapery-size-card {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-top: 16px;
  .title {
    width: 90px;
    text-align: right;
    margin-right: 6px;
    color: var(--font-tips);
  }
  .main-box {
    flex: 1;
    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      .row-box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        color: var(--font-tips);
        .txt {
          width: 50px;
        }
        .wrap {
          flex: 1;
          position: relative;
          i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 12px;
          }
        }
        input {
          border: 1px solid #CACDD1;
          width: 230px;
          height: 32px;
          text-align: right;
          padding-right: 36px;
          margin-top: -2px;
          color: var(--font-tips);
        }
      }
    }
  }
}
.info-card {
  display: flex;
  line-height: 40px;
  margin-top: 16px;
  .title {
    width: 90px;
    margin-right: 6px;
    text-align: right;
    color: var(--font-tips);
  }
  .main {
    display: flex;
    align-items: center;
    flex: 1;
    .item {
      background-color: #f8f8f8;
      width: 120px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      margin-right: 20px;
      &.active {
        background-color: #4D4D4D;
        color: #fff;
      }
    }
    .item:hover {
      cursor: pointer;
    }
    .not-allowed:hover {
      cursor: not-allowed;
    }
  }
}
.zone-info-card {
  display: flex;
  align-items: center;
  margin-top: 16px;
  .title {
    width: 90px;
    text-align: right;
    margin-right: 6px;
    color: var(--font-tips);
  }
  .main {
    display: flex;
    align-items: center;
    flex: 1;
    .zone-card {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      align-items: center;
      white-space: nowrap;
      width: 60px;
      height: 56px;
      background-color: #f8f8f8;
      border-radius: 6px;
      margin-right: 10px;
      &.active {
        background-color: #4D4D4D;
        .txt-box {
          color: #fff;
        }
        .icon-box {
          .iconfont {
            color: #fff;
          }
        }
      }
      .icon-box {
        flex: 1;
        margin-bottom: 8px;
        position: relative;

        .iconfont {
          font-size: 12px;
        }
      }
      .txt-box {
        font-size: 12px;
        color: var(--font-tips);
      }

    }
    .zone-card:hover {
      cursor: pointer;
    }
  }
}
.curtain-scale {
  padding: 6px 0 0 9px;
}
.remark-box {
  margin-top: 20px;
  .cart-order-remark {
    display: flex;
    .title {
      width: 105px;
      text-align: right;
      margin-right: 6px;
      color: var(--font-tips);
    }
  }
}
.btn-wrap {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px;
  background-color: #F2F2F2;
  border: 1px solid #F2F2F2;
  .check-box-btn {
    margin-right: 30px;
    .txt {
      margin-left: 4px;
    }
  }
  .old-price {
    margin-right: 20px;
    .txt {
      color: #666;
    }
    .price {
      font-size: 20px;
      color: #666;
      text-decoration: line-through;
    }
  }
  .custom-price {
    margin-right: 100px;
    .txt {
      color: #666;
    }
    .txt-hint {
      color: var(--font-high);
    }
    .hint {
      font-weight: 700;
      font-size: 20px;
      color: var(--font-high);
    }
  }
  .buy-btn {
    width: 150px;
    height: 40px;
    border: 1px solid var(--color);
    font-size: 16px;
    font-weight: 600;
    color: var(--color);
    margin-right: 24px;
    cursor: pointer;
    background-color: #fff;
    &:hover {
      opacity: .8;
    }
  }

  .add-btn {
    width: 150px;
    height: 40px;
    background-color: var(--color);
    border: 0 none;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    cursor: pointer;
    margin-right: 32px;

    &:hover {
      opacity: .8;
    }
  }
}
.goods-detail-btn-wrap {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: #ffffff;
  border: none;
}
.add-box {
  display: flex;
  margin-top: 16px;
  .title {
    width: 90px;
    text-align: right;
    margin-right: 6px;
    color: var(--font-tips);
  }
  .add-item {
    position: relative;
    background-color: #F2F2F2;
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .add-item:hover {
    cursor: pointer;
  }
  .add-item::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 15px;
    border-top: 1px solid #fff;
  }
  .add-item::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 15px;
    border-left: 1px solid #fff;
  }
}
.curtain-liner-page-main {
  .account-details-page-main-body {
    .cont-table {
      .check-box {
        display: flex;
        align-items: center;
        height: auto;
        .img-box {
          margin-left: 10px;
        }
      }
      td {
        .img-box {
          border: none;
        }
      }
    }
    .search-box {
      .wrap-box {
        width: 30%;
        padding: 16px 16px 6px 16px;
      }
    }
  }
}
.liner-box {
  display: flex;
  margin-top: 16px;
  .title {
    width: 90px;
    margin-right: 6px;
    text-align: right;
    color: var(--font-tips);
  }
  .main {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .liner {
    width: 80px;
    height: 80px;
    .goods-img-box {
      position: relative;
      width: 80px;
      height: 80px;
      border: 1px solid var(--border-middle);
      .svg-icon {
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        padding: 2px;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .liner:hover {
    cursor: pointer;
  }
  .info-box {
    flex: 1;
    margin-left: 10px;
    margin-top: -30px;
    font-size: 12px;
    color: var(--font-normal);
  }
}
.custom-curtain-modal {
  .tips {
    color: var(--font-normal);
  }
  .ant-modal-body {
    padding: 0;
    .product-info-row {
      padding: 24px 24px 80px 24px;
      .number-wrap {
        .title {
          margin-bottom: 6px;
        }
      }
    }
    .ant-input-number {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
    }
  }
}
.reminder {
  margin-top: 16px;
  padding-left: 88px;
  .reminder-title {
    color: var(--font-tips);
  }
  .reminder-content {
    color: var(--font-normal);
    font-size: 13px;
  }
}
.customize-goods-list-box-page {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  padding: 12px;
  background-color: #fff;
  .goods-goods-card-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    position: relative;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    img {
      width: 216px;
      height: 259px;
    }
    .goods-info-box {
      padding: 0 0 8px 0;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      .name {
        margin-top: 8px;
      }
    }
    .icon-selected {
      position: absolute;
      right: -1px;
      bottom: -1px;
      line-height: 1;
      font-size: 18px;
      text-align: right;
      visibility: hidden;
      color: var(--color);
    }
    &.active {
      border: 1px solid #EA382E;
      .icon-selected {
        color: #EA382E;
        visibility: visible;
      }
      .goods-info-box {
        color: #EA382E;
      }
    }
  }
  .goods-goods-card-box-customize-curtain {
    img {
      width: 216px;
      height: 216px;
    }
  }
}
.case-grid-box {
  .goods-goods-card-box {
    padding: 8px;
    img {
      width: 307px;
      height: 307px;
    }
    .video-box-case {
      position: relative;
      width: 300px;
      height: 300px;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        max-width: none;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .user-info-card {
      .info-box {
        display: flex;
        padding: 0 10px 10px 2px;
        .img-tips {
          display: flex;
          align-items: center;
          color: var(--font-tips);
          .svg-icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
        }
      }
      .user-card {
        flex: 1;
        .head-img {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
}
.curtain-pop-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  padding: 16px 26px 30px;
  .item {
    width: 50%;
    display: flex;
    line-height: 50px;
    .txt {
      color: #666;
      width: 88px;
    }
    .label {
      color: #333;
    }
  }
}
.curtainInfo-box {
  .curtain-detail {
    margin-left: 20px;
    color: #ff9f13;
    cursor: pointer;
  }
}
.curtain-style-name {
  margin: 10px 0 10px 10px;
  color: var(--font-tips);
  .style-select-btn {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
    color: #7DCDF3;
  }
}
.account-details-page-main-body {
  .head-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 40px;
  }
  .cont-table {
    th {
      text-align: center;
    }
    td {
      text-align: center;
    }
  }
}
.order-create-tips-modal {
  .ant-modal-body {
    padding: 0;
    .tips-box {
      padding: 18px 24px 24px 24px;
      color: var(--font-normal);
    }
  }
}
