.page-reset {
  p {
    margin: 0;
  }

  .ant-spin {
    color: var(--color);
  }

  .ant-spin-dot-item {
    background-color: var(--color);
  }

  // 分页
  .ant-pagination-item {
    &:hover,
    &:focus {
      border-color: var(--color);

      a {
        color: var(--color);
      }
    }
  }

  .ant-pagination-item-active {
    border-color: var(--color);

    a {
      color: var(--color);
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    &:focus,
    &:hover {
      a {
        color: var(--color);
      }

      .ant-pagination-item-link {
        color: var(--color);
        border-color: var(--color);
      }
    }
  }

  .ant-select-selection {
    &:focus,
    &:hover {
      border-color: var(--color);
    }
  }

  // 搜索框
  .ant-input:hover {
    border-color: var(--color);
  }

  .ant-input:focus {
    border-color: var(--color);
    box-shadow: 0 0 0 2px var(--color-hover);
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: var(--color);
  }

  .ant-btn-primary {
    background-color: var(--color);
    border-color: var(--color);
    color: #fff;

    &:hover, &:focus {
      background-color: var(--color-active);
      border-color: var(--color-active);
    }
  }

  .ant-btn-background-ghost.ant-btn-primary {
    color: var(--color);
    background-color: transparent;
    border-color: var(--color);

    &:hover,
    &:focus {
      color: var(--color-active);
      background-color: var(--color-active);
      border-color: var(--color-active);
    }
  }

  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: var(--color-hover);
  }

  .ant-select-open .ant-select-selection {
    border-color: var(--color);
    box-shadow: 0 0 0 2px var(--color-hover);
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: var(--color-hover);
  }

  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: var(--color);
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background-color: var(--color);
  }

  .ant-calendar-date:hover {
    background: var(--color-hover);
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background-color: var(--color-hover);
  }

  .ant-calendar-today .ant-calendar-date {
    color: var(--color);
    border-color: var(--color);
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
    background: var(--color);
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: var(--color);
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    color: #fff;
    background: var(--color);
  }

  .ant-calendar-header a:hover {
    color: var(--color);
  }

  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
    color: #fff;
    background: var(--color);
  }

  .ant-calendar-month-panel-month:hover {
    background: var(--color-hover);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color);
    border-color: var(--color);
  }

  .ant-checkbox-checked::after {
    border-color: var(--color);
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--color);
  }

  .ant-typography a {
    color: inherit;
  }

  .ant-typography a:focus, .ant-typography a:hover {
    color: var(--color);
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: var(--color);
  }

  .ant-radio-checked::after {
    border: 1px solid var(--color);
  }

  .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
    border-color: var(--color-active);
  }

  .ant-radio-inner::after {
    background-color: var(--color);
  }

  textarea.ant-input {
    resize: none;
  }

  .ant-radio-button-wrapper:hover {
    color: var(--color-active);
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: var(--color);
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: var(--color);
    border-color: var(--color);
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: var(--color-active);
    border-color: var(--color-active);
  }

  .ant-input-affix-wrapper:hover {
    border-color: var(--color);
    border-right-width: 1px !important;
  }

  .ant-modal-title {
    text-align: center;
  }

  .confirm-btn {
    width: 120px;
    height: 42px;
    text-align: center;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: var(--color);
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--color);
  }

  .ant-pagination-next:focus .ant-pagination-item-link {
    background-color: #fff;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: var(--color);
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--color);
  }

  .ant-tabs-ink-bar {
    background: var(--color);
  }

  .ant-carousel .slick-dots {
    z-index: 2;
  }
  .layout-carousel-box {
    .ant-carousel {
      .slick-slide {
        display: flex !important;
        justify-content: center !important;
      }
    }
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: var(--color);
  }
}



